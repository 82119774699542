import { Box, Container, Divider, Grid, MenuItem, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import CustomModal from '../common/CustomModal'
import CustomTitle from '../common/CustomTitle'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import * as yup from "yup";
import CustomInput from '../common/CustomInput';
import CustomImageUploader from '../common/CustomImageUploder';
import CustomSelect from '../common/CustomSelect';
import CustomTextArea from '../common/CustomTextArea';
import CustomButton from '../common/CustomButton';
import CustomBackArrow from '../common/CustomBackArrow';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPostListShow, updatePost } from '../../api/post';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IMG_URL } from '../../config';
import { useSnackbar } from '../../hooks/SnackBarHook';
import CustomBackDrop from '../common/CustomBackDrop';



export const PostEditView = ({ close, open, label }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const { postId } = useParams();
    const showSnackbar = useSnackbar();

    const { data, isError, isLoading, isFetched, refetch } = useQuery({ queryKey: ['postshow'], queryFn: () => getPostListShow(postId) });


    console.log({ data })



    const [companyLogoPreview, setcompanyLogoPreview] = useState(null);
    const [imagefileCmpny, setImagefileCmpny] = useState(null);
    const [coverPreview, setcoverPreview] = useState(null);
    const [imagefileCover, setImagefileCover] = useState(null);
    const [statusSelect, setStatusSelect] = useState(null);
    const [pstatusSelect, setPstatusSelect] = useState(null);
    const schema = object().shape({


    });

    const {
        handleSubmit,
        control,
        setValue,
        setError,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: '',
            posted_data: '',
            deadline: '',
            post_type: '',
            payment_status: '',
            title: '',
            description: '',
            button_type: '',
            total_participants: '',
            winner: '',
            winner_email: '',
            winner_mobile: '',
            comment: '',
            status: '',
        }
    });

    useEffect(() => {
        if (data?.data?.data) {
            reset()
            setValue('first_name', data?.data?.data?.user?.first_name);
            setValue('posted_data', data?.data?.data?.created_date)
            setValue('deadline', data?.data?.data?.deadline);
            setValue('post_type', data?.data?.data?.type);
            setValue('payment_status', data?.data?.data?.payment ? data?.data?.data?.payment?.payment_status : '');
            setValue('title', data?.data?.data?.title);
            setValue('description', data?.data?.data?.description);
            setValue('button_type', data?.data?.data?.button_type);
            setValue('total_participants', data?.data?.data?.total_participants);
            setValue('winner', data?.data?.data?.winner?.user?.first_name);
            setValue('winner_email', data?.data?.data?.winner?.user?.email);
            setValue('winner_mobile', data?.data?.data?.winner?.user?.mobile);
            setValue("comment", data?.data?.data?.approval_statuses[data?.data?.data?.approval_statuses?.length - 1]?.comment);
            setcompanyLogoPreview(IMG_URL + data?.data?.data?.post_image)
            setcoverPreview(IMG_URL + data?.data?.data?.price_image)
            setStatusSelect(data?.data?.data?.status);
            setPstatusSelect(data?.data?.data?.payment ? data?.data?.data?.payment?.payment_status : '');

            // const datas = data?.data?.data;

            // let formdata = {
            //     first_name: datas?.user?.first_name,
            //     posted_data: datas?.created_date,
            //     deadline: datas?.deadline,
            //     post_type: datas?.type,
            //     title: datas?.title,
            //     description: datas?.description,
            //     button_type: datas?.button_type,
            //     total_participants: datas?.total_participants,
            //     winner: datas?.winner?.user?.first_name,
            //     comment: datas?.approval_statuses[datas?.approval_statuses?.length - 1]?.comment
            // }

            //reset(formdata)

        }
    }, [data?.data?.data]);




    const resetPost = () => {
        setcompanyLogoPreview(null);
        setcoverPreview(null);
        setImagefileCmpny(null);
        setImagefileCover(null);
        reset({
            first_name: '',
            posted_data: '',
            deadline: '',
            post_type: '',
            title: '',
            description: '',
            button_type: '',
            total_participants: '',
            winner: '',
            winner_email: '',
            winner_mobile: '',
            comment: '',
            status: '',
        })
    }



    const onChageStatus = useCallback((e) => {
        const { value } = e.target;
        setValue('status', value)
        setStatusSelect(value)
    }, [statusSelect])

    const onPaymentStatus = useCallback((e) => {
        const { value } = e.target;
        setValue('payment_status', value)
        setPstatusSelect(value)
    }, [pstatusSelect])

    const ImageUploderCompany = () => {

    }
    const ImageUploderCover = () => {

    }



    const { mutate, isLoading: settingLoading, error } = useMutation({
        mutationFn: updatePost,
        onSuccess: async (data) => {
            showSnackbar('Updated succesfully!', 'success');
            navigate(-1)
        },
        onError: (error, variables, context) => {
            showSnackbar(error?.message, 'error');
        },
        // onSettled: async () => {
        //     console.log("I'm second!")
        // },
    })


    const SubmitPost = (data) => {
        let payload = {
            id: postId,
            status: data?.status,
            payment_status: data?.payment_status,
            comment: data?.comment ? data?.comment : ''
        }
        mutate(payload)
    }

    return (
        <Box px={2} py={2}>
            <CustomBackArrow label={`${state} Post`} reset={resetPost} />
            <Box px={5}>

                <Grid container spacing={2} my={2} >
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomInput
                            readonly={(state === 'View' || state === 'Edit') ? true : false}
                            control={control}
                            error={errors.first_name}
                            fieldName="first_name"
                            fieldLabel="Merchant Name"
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomInput
                            readonly={(state === 'View' || state === 'Edit') ? true : false}
                            control={control}
                            error={errors.posted_data}
                            fieldName="posted_data"
                            fieldLabel="Posted Date & Time"
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomInput
                            readonly={(state === 'View' || state === 'Edit') ? true : false}
                            control={control}
                            error={errors.deadline}
                            fieldName="deadline"
                            fieldLabel="Deadline"
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomInput

                            readonly={(state === 'View' || state === 'Edit') ? true : false}
                            control={control}
                            error={errors.post_type}
                            fieldName="post_type"
                            fieldLabel="Post Type"
                        />
                    </Grid>
                    {data?.data?.data?.type === 'premium' ? (<Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        {/* <CustomInput
                            readonly={true}
                            control={control}
                            error={errors.post_type}
                            fieldName="payment_status"
                            fieldLabel="Payment Status"
                        /> */}
                        <CustomSelect
                            readOnly={state === 'View' ? true : false}
                            control={control}
                            error={errors.status}
                            fieldName="payment_status"
                            fieldLabel="Payment Status"
                            size="16px"
                            value={pstatusSelect}
                            onChangeValue={(e) => onPaymentStatus(e)}
                        >
                            <MenuItem value="" disabled >
                                <em>Payment Status</em>
                            </MenuItem>
                            {[{ id: 1, name: 'Pending', value: 'pending' }, { id: 2, name: 'Paid', value: 'completed' },
                                // { id: 2, name: 'Pending', value: 'pending' }
                            ].map((res, i) => (
                                <MenuItem value={res.value} >
                                    {res?.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>) : null}
                    {data?.data?.data?.type === 'normal' ? (<Grid item xl={2} lg={2} md={1} sm={4} xs={12}></Grid>) : null}
                    <Grid item xl={2} lg={2} md={1} sm={4} xs={12}></Grid>
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomTextArea
                            readOnly={true}
                            control={control}
                            error={errors.title}
                            fieldName="title"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Post Title"
                        />
                    </Grid>
                    <Grid item xl={10} lg={10} md={3} sm={4} xs={12}>
                        <CustomTextArea
                            readOnly={true}
                            control={control}
                            error={errors.description}
                            fieldName="description"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Post Description"
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomInput
                            readonly={(state === 'View' || state === 'Edit') ? true : false}
                            control={control}
                            error={errors.button_type}
                            fieldName="button_type"
                            fieldLabel="Post Button Type"
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomImageUploader
                            ICON={""}
                            hide={(state === 'View' || state === 'Edit') ? true : false}
                            viewImage={companyLogoPreview}
                            error={errors.photo}
                            fieldName="photo"
                            placeholder={``}
                            fieldLabel={"Post Image"}
                            control={control}
                            height={{ xl: 160, lg: 150, md: 150, sm: 150, xs: 140 }}
                            max={5}
                            onChangeValue={ImageUploderCompany}
                            preview={imagefileCmpny}
                            previewEditimage={""}
                            type={"file"}
                            background="#e7f5f7"
                            myid="contained-button-file2"
                            width={'100%'}
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                        <CustomImageUploader
                            ICON={""}
                            hide={(state === 'View' || state === 'Edit') ? true : false}
                            viewImage={coverPreview}
                            error={errors.photo}
                            fieldName="photo"
                            placeholder={``}
                            fieldLabel={"Prize Image"}
                            control={control}
                            height={{ xl: 160, lg: 150, md: 150, sm: 150, xs: 140 }}
                            max={5}
                            onChangeValue={ImageUploderCover}
                            preview={imagefileCover}
                            previewEditimage={""}
                            type={"file"}
                            background="#e7f5f7"
                            myid="contained-button-file1"
                            width={'100%'}
                        />
                    </Grid>
                </Grid>
                <Divider />
                <CustomTitle label={'Participants & Winners'} />
                <Grid container spacing={2} my={2} >
                    <Grid item xl={2.4} lg={2.4} md={3} sm={4} xs={12}>
                        <CustomInput
                            readonly={(state === 'View' || state === 'Edit') ? true : false}
                            control={control}
                            error={errors.total_participants}
                            fieldName="total_participants"
                            fieldLabel="Total Participants"
                        />
                    </Grid>
                    {data?.data?.data?.winner ? (
                        <>
                            <Grid item xl={2.4} lg={2.4} md={3} sm={4} xs={12}>
                                <CustomInput
                                    readonly={(state === 'View' || state === 'Edit') ? true : false}
                                    control={control}
                                    error={errors.winner}
                                    fieldName="winner"
                                    fieldLabel="Selected Winner Name"
                                />
                            </Grid>
                            <Grid item xl={2.4} lg={2.4} md={3} sm={4} xs={12}>
                                <CustomInput
                                    readonly={(state === 'View' || state === 'Edit') ? true : false}
                                    control={control}
                                    error={errors.winner_email}
                                    fieldName="winner_email"
                                    fieldLabel="Winner Email"
                                />
                            </Grid>
                            <Grid item xl={2.4} lg={2.4} md={3} sm={4} xs={12}>
                                <CustomInput
                                    readonly={(state === 'View' || state === 'Edit') ? true : false}
                                    control={control}
                                    error={errors.winner_mobile}
                                    fieldName="winner_mobile"
                                    fieldLabel="Winner Mobile"
                                />
                            </Grid>
                        </>) : null}
                </Grid>
                <Divider />
                <CustomTitle label={'Status'} />
                <Grid container spacing={3} my={2} >
                    <Grid item xl={2.4} lg={2.4} md={3} sm={4} xs={12}>
                        <CustomSelect
                            readOnly={state === 'View' ? true : false}
                            control={control}
                            error={errors.status}
                            fieldName="status"
                            fieldLabel="Approval Status"
                            size="16px"
                            value={statusSelect}
                            onChangeValue={(e) => onChageStatus(e)}
                        >
                            <MenuItem value="" disabled >
                                <em>Status</em>
                            </MenuItem>
                            {[{ id: 1, name: 'Approved', value: 'approved' }, { id: 2, name: 'Rejected', value: 'reject' },
                                // { id: 2, name: 'Pending', value: 'pending' }
                            ].map((res, i) => (
                                <MenuItem value={res.value} >
                                    {res?.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                        <CustomTextArea
                            readOnly={state === 'View' ? true : false}
                            control={control}
                            error={errors.comment}
                            fieldName="comment"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Remarks* (If Rejected)"
                        />
                    </Grid>
                </Grid>
                {state === 'Edit' &&
                    <Box display={'flex'} justifyContent={'center'} py={5}>
                        <CustomButton isIcon={false} onClick={handleSubmit(SubmitPost)} label={'Update'} width={{ xl: '30%', lg: '30%', md: '30%', sm: '60%', xs: '100%' }} />

                    </Box>}
                <CustomBackDrop loading={isLoading} />
            </Box>
        </Box>


    )
}
