
export const env = "live"

const url = {
    dev: "https://apild.diginestsolutions.in/public/api/",
    live: "https://ldapi.luckydrawmaldives.com/public/api/"
}

const IMAGE = {
    dev: 'https://apild.diginestsolutions.in/public',
    live: "https://ldapi.luckydrawmaldives.com/public"

}

export const BASE_URL = `${url[env]}`

export const IMG_URL = IMAGE[env]