import { axiosInstance } from "../customAxios";


export const getAdvertisements = async () => {
    try {
        const response = await axiosInstance.get('admin/advertisement/list');
        return response
    }
    catch (error) {
        throw error;
    }
}

export const deleteItem = async(id) => {
    try {
        const response = await axiosInstance.delete(`admin/advertisement/delete/${id}`);
        return response
    }
    catch (error) {
        throw error;
    }
}