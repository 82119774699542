import { Box, Stack, Tooltip } from '@mui/material'
import React, { useCallback, useState } from 'react'
import CustomHeading from '../components/common/CustomHeading'
import DataTable from '../components/common/CustomTable';
import useModal from '../hooks/ModalHook';
import View from '../components/OrderMangement/View';
import { ICONS } from '../assets/ICONS';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteItem, getAdvertisements } from '../api/advertisement';
import { Button } from '@mui/material'
import { COLOURS } from '../assets/COLORS';
import CustomModal from '../components/common/CustomModal';
import NewAdvertisement from '../components/Advertisement/NewAdvertisement';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import CustomButton from '../components/common/CustomButton';
import { IMG_URL } from '../config';
import { useSnackbar } from '../hooks/SnackBarHook';




const Advertisements = () => {
    const [open, setOpen] = useState(false);
    const showSnackbar = useSnackbar();

    const { data, isError, isLoading, isFetched, refetch } = useQuery({ queryKey: ['advertisements'], queryFn: getAdvertisements });



    const { mutate, isLoading: deleteLoading, error } = useMutation({
        mutationFn: deleteItem,
        onSuccess: (data) => { 
            
            refetch()
            showSnackbar('Deleted succesfully!', 'success');
         },
        // onError: (error) => { // Function to handle errors (optional) },
        // onSettled: (data, error) => { // Function to run after success or error (optional) },
        // onMutate: async (data) => { // Function for optimistic updates before server response (optional) }
    });



    

    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'image',
            headerName: 'Image',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                console.log({ row })
                return (
                    <Stack alignItems={'center'} gap={1} direction={'row'}>
                        <img src={`${IMG_URL}${row?.image}`} width={250} height={50} />
                    </Stack>
                )
            },
        },
        {
            field: 'link',
            headerName: 'Link',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'order',
            headerName: 'Order',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {

            field: 'Action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => (
                <ConfirmationDialog
                    title="Confirm Deletion"
                    message="Are you sure you want to delete this item?"
                    onConfirm={() => mutate(row?.id)}
                    onCancel={() => console.log('Delete cancelled')}
                />
            ),
        }
    ];




    const closeModal = () => {
        refetch()
        setOpen(false);
    };



    return (
        <Box px={5} py={2} width={'95%'} display={'flex'} flexDirection={'column'} gap={2}>
            <CustomHeading label={'Advertisements'} isEnable={true} />
            <Box mt={7} display={"flex"} flexDirection={"column"} >
                <Box display={'flex'} justifyContent={'flex-end'}>
                <CustomButton 
                    onClick={() => setOpen(true)}
                    label={'Add'}
                    width={100}
                />
                </Box>
                <DataTable id={'id'} columns={columns} rows={data?.data?.data ? data?.data?.data : []} />
            </Box>
            <CustomModal open={open} close={() => setOpen(false)} label="Advertisements">
                <NewAdvertisement close={closeModal} />
            </CustomModal>
        </Box>
    )
}

export default Advertisements