import React, { useCallback } from 'react'
import { ICONS } from '../../assets/ICONS'
import { Box, Typography, Button } from '@mui/material'

import { COLOURS } from '../../assets/COLORS'
import { useNavigate } from 'react-router-dom'

const CustomBackArrow = ({ label, close, back, MT, reset }) => {
const navigate = useNavigate()


    const NavigateToClose = useCallback(() => {
        if(close){
            close()
        }
        else{
            if(reset){
                reset()
            }
            setTimeout(() => {
                navigate(-1)
            }, 1000)
        }
       
    }, [])
    return (
        <Button sx={{ display: 'flex', alignItems: 'center', gap: 2, cursor: 'pointer',mt:10 }} onClick={!back ? NavigateToClose : null}>
            {!back && <ICONS.arrowBack.component sx={ICONS.arrowBack.sx} />}
            <Typography sx={{ fontSize: 26, fontFamily: 'Outfit-Bold', color: COLOURS.secondary }}>{label}</Typography>
        </Button>
    )
}

export default CustomBackArrow