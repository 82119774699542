import React, { useState } from 'react';
import CustomSelect from '../common/CustomSelect';
import CustomInput from '../common/CustomInput';
import CustomImageUploader from '../common/CustomImageUploder';
import { Grid, MenuItem } from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { string, mixed, object } from 'yup';
import CustomButton from '../common/CustomButton';
import { axiosInstance } from '../../customAxios';
import { useSnackbar } from '../../hooks/SnackBarHook';

const NewAdvertisement = ({ close }) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState('');
  const showSnackbar = useSnackbar();

  const commonSchema = object().shape({
    type: string().required('Required'),
    image: mixed().required('Image is required').test(
      "fileFormat",
      "Unsupported Format",
      value => value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    ),
    link: string().nullable().required('Link Required'),
    order: string().required('Order Required')
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(commonSchema),
  });

  const imageSize = (fileUpload, width, height) => {
    return new Promise((resolve, reject) => {
      if (!fileUpload) {
        reject(new Error("No file uploaded"));
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileUpload);

      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          if (image.width === width && image.height === height) {
            resolve(true);
          } else {
            resolve(false);
          }
        };

        image.onerror = () => {
          reject(new Error("Invalid image"));
        };
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
    });
  };

  const ImageUploader = async (file, type) => {
    if (!file) {
        setImage(null);
        throw new Error("No file uploaded");
    }

    try {
        let isValidSize = false;
        if (type === "slider") {
            isValidSize = await imageSize(file, 770, 90);
        } else if (type === "left" || type === "right") {
            isValidSize = await imageSize(file, 500, 175);
        }

        if (file.size <= 2000000 && isValidSize) {
            setImage(file);
            setValue("image", file);
            clearErrors("image");
        } else {
            setImage(null);
            throw new Error("Invalid image size or dimensions");
        }
    } catch (error) {
        setImage(null);
        throw new Error(error.message);
    }
};


  const changeImage = async (file) => {
    const type = getValues("type");
    try {
        await ImageUploader(file, type);
    } catch (error) {
        setError("image", { message: error.message });
    }
  };


  const changeHelper = (e) => {
    if (e === 'slider') {
        setHelperText('Recommended size: 770x90');
    } else if (e === 'left' || e === 'right') {
        setHelperText('Recommended size: 500x175');
    }

    // Reset image field and clear error
    setImage(null);
    setValue('image', null);
    clearErrors('image');
};

  const SubmitForm = async (data) => {
    try {
      setLoading(true);
      let response = await axiosInstance.post('/admin/advertisement/create', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      close();
    } catch (error) {
      // handle error
      showSnackbar(error?.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2} mt={5}>
      <Grid item xs={12} md={4} lg={3}>
        <CustomSelect
          readOnly={false}
          control={control}
          error={errors.type}
          fieldName="type"
          fieldLabel="Type"
          size="16px"
        >
          <MenuItem value="" disabled >
            <em>Status</em>
          </MenuItem>
          {[{ id: 'slider', name: 'Slider', value: 'slider' }, { id: 'left', name: 'Left', value: 'left' }, { id: 'right', name: 'Right', value: 'right' }
          ].map((res, i) => (
            <MenuItem value={res.value} onClick={() => changeHelper(res.value)}>
              {res?.name}
            </MenuItem>
          ))}
        </CustomSelect>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <CustomInput
          readonly={false}
          control={control}
          error={errors.link}
          fieldName="link"
          fieldLabel="Link"
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <CustomInput
          readonly={false}
          control={control}
          error={errors.order}
          fieldName="order"
          fieldLabel="Order"
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <CustomImageUploader
          ICON={""}
          hide={false}
          error={errors.image}
          fieldName="image"
          placeholder={``}
          fieldLabel={"Image"}
          control={control}
          height={{ xl: 160, lg: 150, md: 150, sm: 150, xs: 140 }}
          max={5}
          onChangeValue={changeImage}
          preview={image}
          previewEditimage={""}
          type={"file"}
          background="#e7f5f7"
          myid="contained-button-file1"
          width={'100%'}
          helperText={helperText}
        />
      </Grid>
      <Grid item xs={12} flex={1} alignItems={'flex-end'} justifyContent={'flex-end'}>
        <CustomButton
          loading={loading}
          onClick={handleSubmit(SubmitForm)}
          width={100}
          label={'Confirm'}
          isIcon={false}
        />
      </Grid>
    </Grid>
  );
}

export default NewAdvertisement;
